import L from "leaflet";

import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import iconUrl from "../assets/icon/marker-green.svg";


export default () => {
  if (typeof window !== 'undefined') {
    return L.icon({
      iconUrl: iconUrl,
      iconRetinaUrl: iconUrl,
      shadowUrl: shadowUrl,
      iconSize: [30, 30],
      iconAnchor: [14, 14],
      popupAnchor: null,
      tooltipAnchor: [7, -12],
      shadowSize: [31, 31],
    });
  }
  return null
}
